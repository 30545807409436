import connectorAuthorisations from "./connectorAuthorisations";
import dueDiligence from "./due_diligence_reports";
import organisations from "./organisations";
import partnerAccessToOrgs from "./partners";
import sftpCredentials from "./sftpCredentials";
import transformations from "./transformations";
import users from "./users";
import workflows from "./workflows";

export default {
  connectorAuthorisations,
  organisations,
  sftpCredentials,
  users,
  dueDiligence,
  workflows,
  partnerAccessToOrgs,
  transformations,
};
