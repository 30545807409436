import { useMutation } from "@tanstack/react-query";
import { makeQueryRequest, HttpMethod, Response } from "common/services/APIService";
import { HOST } from "common/utils/constants";

const generateDueDiligenceReport = async (data: { partnerId: number; organisationId: number }) => {
  return await makeQueryRequest(
    `${HOST}/v1/partner-portal/due-diligence-reports/generate?partner_id=${data.partnerId}&organisation_id=${data.organisationId}`,
    { method: HttpMethod.POST }
  );
};

const downloadDueDiligenceReport = async (data: {
  dueDiligenceId: string | undefined;
  organisationId: number;
  partnerId: number;
  selectedDownload: string | undefined;
}): Promise<Response<{ file_url: string }>> => {
  let parameterString = "";

  parameterString += `partner_id=${data.partnerId}&`;
  parameterString += `organisation_id=${data.organisationId}&`;
  if (data.dueDiligenceId !== undefined) {
    parameterString += `due_diligence_report_id=${data.dueDiligenceId}&`;
  }
  if (data.selectedDownload !== undefined) {
    parameterString += `report_type=${data.selectedDownload}&`;
  }
  parameterString = parameterString.slice(0, -1);

  return await makeQueryRequest(`${HOST}/v1/partner-portal/due-diligence-reports/download?${parameterString}`, {
    method: HttpMethod.GET,
  });
};

const dueDiligence = {
  generate: (onSuccess: () => void, onError: () => void) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMutation(generateDueDiligenceReport, {
      onSuccess: onSuccess,
      onError: onError,
    }),
  download: (onSuccess: (data: Response<{ file_url: string }>) => void, onError: () => void) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMutation(downloadDueDiligenceReport, {
      onSuccess: onSuccess,
      onError: onError,
    }),
};

export default dueDiligence;
