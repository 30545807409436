import { useMutation } from "@tanstack/react-query";
import { makeQueryRequest, HttpMethod, Response } from "common/services/APIService";
import { HOST } from "common/utils/constants";

const launchWorkFlow = async (partnerId: number, organisationId: number): Promise<Response<null>> => {
  return await makeQueryRequest(
    `${HOST}/v1/partner-portal/workflows/launch?partner_id=${partnerId}&organisation_id=${organisationId}`,
    {
      method: HttpMethod.POST,
    }
  );
};

const workflows = {
  useLaunchWorkFlow: ({
    partnerId,
    onSuccess,
    onError,
  }: {
    partnerId: number;
    onSuccess: (data: Response<null>) => void;
    onError: () => void;
  }) =>
    useMutation({
      mutationFn: (organisationId: number) => launchWorkFlow(partnerId, organisationId),
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError,
    }),
};

export default workflows;
