import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import APIService, { APICallState, APICallStateType } from "app/services/APIService";
import { APIServiceFunctionArg } from "common/services/APIService/types";
import { AsyncThunkReturnType } from "common/slices/types";

export const getConnectorAuthSchema = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.connectors.getAuthSchema>
>(
  "connectors/connectorAuth/getConnectorAuthSchema",
  async (data) => await APIService.connectors.getAuthSchema(data).then((res) => res.json())
);

export const submitAuth = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.connectors.submitAuth>
>(
  "connectors/connectorAuth/submitAuth",
  async (data) => await APIService.connectors.submitAuth(data).then((res) => res.json())
);

export interface ConnectorAuthConfig {
  schema: any;
  ui_schema: any;
  connector_type: string;
  oauth_flow: boolean;
  airbyte_name: string;
}

export interface ConnectorAuthState {
  connectorAuthConfig: ConnectorAuthConfig | undefined;
  status: APICallStateType;
  submitStatus: APICallStateType;
}

// Define the initial state using that type
export const initialState: ConnectorAuthState = {
  connectorAuthConfig: undefined,
  status: undefined,
  submitStatus: undefined,
};

export const authSlice = createSlice({
  name: "connectorAuth",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConnectorAuthSchema.pending, (state) => {
      state.status = APICallState.LOADING;
    });

    builder.addCase(getConnectorAuthSchema.fulfilled, (state, action) => {
      if (action.payload.status_code === 200) {
        state.status = APICallState.SUCCESS;
        state.connectorAuthConfig = action.payload.data.connector;
      } else {
        state.status = APICallState.FAILED;
      }
    });

    builder.addCase(getConnectorAuthSchema.rejected, (state) => {
      state.status = APICallState.FAILED;
    });

    builder.addCase(submitAuth.pending, (state) => {
      state.submitStatus = APICallState.LOADING;
    });

    builder.addCase(submitAuth.fulfilled, (state, action) => {
      if (action.payload.status_code >= 200 && action.payload.status_code < 300) {
        state.submitStatus = APICallState.SUCCESS;
      } else {
        state.submitStatus = APICallState.FAILED;
      }
    });

    builder.addCase(submitAuth.rejected, (state) => {
      state.submitStatus = APICallState.FAILED;
    });
  },
});

export default authSlice.reducer;
