import { makeQueryRequest, Response, HttpMethod } from "common/services/APIService";
import { HOST } from "common/utils/constants";

// This endpoint is for sending regular http messages instead of ws messages when the message size over the 100KB limit.
const httpMessageHandler = {
  sendHttpMessage: async (organisationId: string | number, requestMessage: any): Promise<Response<any>> =>
    await makeQueryRequest(`${HOST}/v1/http_message_handler?organisation_id=${organisationId}`, {
      method: HttpMethod.POST,
      body: requestMessage,
    }),
};

export default httpMessageHandler;
