import { GAEventType, GAEventActionEnum, GAEventCategoryEnum, GAProductSectionEnum, ProductSectionType } from "./types";

declare const gtag: (...args: (string | Record<string, unknown>)[]) => void;

class EventTracker {
  private publicOrganisationId = "";

  setOrganisationPublicId(publicOrganisationId: string): void {
    this.publicOrganisationId = publicOrganisationId;
  }

  private getCommonPayload() {
    return {
      public_org_id: this.publicOrganisationId,
    };
  }

  trackSignIn(success: boolean) {
    EventTracker.sendGAEvent({
      action: GAEventActionEnum.SIGN_IN,
      category: GAEventCategoryEnum.USER,
      label: "Sign In" + success ? "Successful" : "Fail",
      payload: {
        ...this.getCommonPayload(),
        product_section: GAProductSectionEnum.SIGN_IN,
        sign_in_success: success,
      },
    });
  }

  trackSignOut() {
    EventTracker.sendGAEvent({
      action: GAEventActionEnum.SIGN_OUT,
      category: GAEventCategoryEnum.USER,
      label: "Sign Out Successful",
      payload: {
        ...this.getCommonPayload(),
        product_section: GAProductSectionEnum.SIGN_OUT_ACTION,
      },
    });
  }

  trackSignUpSuccessful() {
    EventTracker.sendGAEvent({
      action: GAEventActionEnum.SIGN_UP,
      category: GAEventCategoryEnum.USER,
      label: "Sign Up Successful",
      payload: {
        ...this.getCommonPayload(),
        product_section: GAProductSectionEnum.SIGN_UP_VERIFIED,
      },
    });
  }

  trackConnectorConfigured() {
    EventTracker.sendGAEvent({
      action: GAEventActionEnum.CONNECTOR_CONFIGURED,
      category: GAEventCategoryEnum.USER,
      label: "Connector Configured Successful",
      payload: {
        ...this.getCommonPayload(),
        product_section: GAProductSectionEnum.CONNECTOR_CONFIGURED_ACTION,
      },
    });
  }

  trackPageView(params: { pageTitle: string; pageLocation: string }) {
    EventTracker.sendGAEvent({
      action: GAEventActionEnum.PAGE_VIEW,
      category: GAEventCategoryEnum.PAGE,
      label: "Page View",
      payload: {
        ...this.getCommonPayload(),
        ...params,
        product_section: params.pageTitle ?? document.title,
      },
    });
  }

  trackLogRocketSession(logRocketUrl: string) {
    EventTracker.sendGAEvent({
      action: GAEventActionEnum.LOG_ROCKET_SESSION,
      category: GAEventCategoryEnum.PAGE,
      label: "Log Rocket Session",
      payload: {
        ...this.getCommonPayload(),
        product_section: GAProductSectionEnum.LOG_ROCKET_SESSION,
        log_rocket_url: logRocketUrl,
      },
    });
  }

  trackInviteMembers() {
    EventTracker.sendGAEvent({
      action: GAEventActionEnum.INVITE_MEMBERS,
      category: GAEventCategoryEnum.USER,
      label: "Invite Members Successful",
      payload: {
        ...this.getCommonPayload(),
        product_section: GAProductSectionEnum.INVITE_MEMBERS_ACTION,
      },
    });
  }

  trackOrganisationSetup() {
    EventTracker.sendGAEvent({
      action: GAEventActionEnum.ORGANISATION_SETUP,
      category: GAEventCategoryEnum.USER,
      label: "Organisation Setup Successful",
      payload: {
        ...this.getCommonPayload(),
        product_section: GAProductSectionEnum.ORGANISATION_SETUP_ACTION,
      },
    });
  }

  trackInternalLinkClick(label: string, productSection?: ProductSectionType) {
    EventTracker.sendGAEvent({
      action: GAEventActionEnum.CLICK,
      category: GAEventCategoryEnum.INTERNAL_LINK,
      label: label,
      payload: {
        ...this.getCommonPayload(),
        product_section: productSection ?? document.title,
      },
    });
  }

  trackButtonClick(label: string, productSection?: ProductSectionType) {
    EventTracker.sendGAEvent({
      action: GAEventActionEnum.CLICK,
      category: GAEventCategoryEnum.BUTTON,
      label: label,
      payload: {
        ...this.getCommonPayload(),
        product_section: productSection ?? document.title,
      },
    });
  }

  trackDropdownClick(label: string, productSection?: ProductSectionType) {
    EventTracker.sendGAEvent({
      action: GAEventActionEnum.CLICK,
      category: GAEventCategoryEnum.DROPDOWN,
      label: label,
      payload: {
        ...this.getCommonPayload(),
        product_section: productSection ?? document.title,
      },
    });
  }

  trackChangeTabClick(label: string, productSection?: ProductSectionType) {
    EventTracker.sendGAEvent({
      action: GAEventActionEnum.CLICK,
      category: GAEventCategoryEnum.CHANGE_TAB,
      label: label,
      payload: {
        ...this.getCommonPayload(),
        product_section: productSection ?? document.title,
      },
    });
  }

  trackDateFilterClick(label: string, productSection?: ProductSectionType) {
    EventTracker.sendGAEvent({
      action: GAEventActionEnum.CLICK,
      category: GAEventCategoryEnum.DATE_FILTER,
      label: label,
      payload: {
        ...this.getCommonPayload(),
        product_section: productSection ?? document.title,
      },
    });
  }

  trackExternalLinkClick(label: string, productSection?: ProductSectionType) {
    EventTracker.sendGAEvent({
      action: GAEventActionEnum.CLICK,
      category: GAEventCategoryEnum.EXTERNAL_LINK,
      label: label,
      payload: {
        ...this.getCommonPayload(),
        product_section: productSection ?? document.title,
      },
    });
  }

  trackFeedbackClick(label: string, productSection?: ProductSectionType) {
    EventTracker.sendGAEvent({
      action: GAEventActionEnum.CLICK,
      category: GAEventCategoryEnum.FEEDBACK,
      label: label,
      payload: {
        ...this.getCommonPayload(),
        product_section: productSection ?? document.title,
      },
    });
  }

  trackCarouselScrollClick(label: string, productSection?: ProductSectionType) {
    EventTracker.sendGAEvent({
      action: GAEventActionEnum.CLICK,
      category: GAEventCategoryEnum.CAROUSEL_SCROLL,
      label: label,
      payload: {
        ...this.getCommonPayload(),
        product_section: productSection ?? document.title,
      },
    });
  }

  private static sendGAEvent(event: GAEventType) {
    if (typeof gtag === "undefined") return;
    gtag("event", event.action, {
      event_category: event.category,
      event_label: event.label,
      ...event.payload,
    });
  }
}

export default new EventTracker();
