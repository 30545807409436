import { useEffect } from "react";

import { REACT_APP_HUBSPOT_TRACKING_ID } from "common/utils/constants";
import { useAppSelector } from "common/utils/store";
import { useLocation } from "react-router-dom";
declare global {
  interface Window {
    _hsq: any[];
  }
}

const _hsq = (window._hsq = window._hsq || []);

const injectHubspotScript = () => {
  const scriptHubspot = document.createElement("script");
  scriptHubspot.src = `//js-eu1.hs-scripts.com/${REACT_APP_HUBSPOT_TRACKING_ID}.js`;
  scriptHubspot.type = "text/javascript";
  scriptHubspot.id = "hs-script-loader";
  scriptHubspot.async = true;
  scriptHubspot.defer = true;
  document.head.appendChild(scriptHubspot);
};

const configure = () => {
  if (!REACT_APP_HUBSPOT_TRACKING_ID) return;
  injectHubspotScript();
};

const updateProps = (email: string, pathname: string) => {
  _hsq.push(["identify", { email }]);
  _hsq.push(["setPath", pathname]);
  _hsq.push(["trackPageView"]);
};

export const HubspotTracker = () => {
  const location = useLocation();
  const { signedIn, user } = useAppSelector(({ auth }) => auth);

  useEffect(() => {
    if (!user) return;
    const { email } = user;
    const { pathname } = location;

    updateProps(email, pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedIn, location]);

  return null;
};

const HubspotServices = {
  configure,
};

export default HubspotServices;
