import makeNoAuthRequest from "common/services/APIService/makeNoAuthRequest";
import makeRequest from "common/services/APIService/makeRequest";
import { HOST } from "common/utils/constants";

const invites = {
  get: (data: { invite_id_hash: string }) => makeNoAuthRequest(`${HOST}/v1/invites/${data.invite_id_hash}`),
  accept: (data: { invite_id_hash: string }) =>
    makeRequest(`${HOST}/v1/invites/${data.invite_id_hash}`, { method: "PATCH" }),
  list: (data: { organisation_id: number }) =>
    makeRequest(`${HOST}/v1/invites?organisation_id=${data.organisation_id}`),
  invalidate: (data: { invite_id_hash: string }) =>
    makeRequest(`${HOST}/v1/invites/${data.invite_id_hash}/invalidate`, { method: "PATCH" }),
};

export default invites;
