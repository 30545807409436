import { useQuery } from "@tanstack/react-query";
import { makeQueryRequest, Response } from "common/services/APIService";
import makeRequest from "common/services/APIService/makeRequest";
import { HOST } from "common/utils/constants";

import { WorkflowStatusResponse } from "./types";

export const fetchWorkflowStatus = async (
  organisationId: number | undefined
): Promise<Response<WorkflowStatusResponse>> => {
  return await makeQueryRequest(`${HOST}/v1/workflows/status?organisation_id=${organisationId}`);
};

const workflows = {
  launch: (organisation_id: number) =>
    makeRequest(`${HOST}/v1/workflows/launch?organisation_id=${organisation_id}`, { method: "POST" }), // TODO: Update with react-query
  useWorkflowStatus: (organisationId: number | undefined) =>
    useQuery({
      queryKey: ["workFlowStatus", organisationId],
      queryFn: () => fetchWorkflowStatus(organisationId),
      enabled: !!organisationId,
    }),
};

export { WorkflowStatusEnum } from "./types";

export default workflows;
