import { useQuery, useQueries, useMutation } from "@tanstack/react-query";
import { ConnectorType } from "common/interfaces/connectors/connectorType";
import { makeQueryRequest, HttpMethod, Response } from "common/services/APIService";
import { HOST } from "common/utils/constants";

const fetchActionSummary = async (
  organisation_id: number | undefined,
  category: string,
  action: string
): Promise<Response<any>> => {
  return await makeQueryRequest(
    `${HOST}/v1/actions/summary?organisation_id=${organisation_id}&category=${category}&action=${action}`
  );
};

const selectActionSummary: (response: { data: { action_categories: ActionItem[] } }) => ActionItem[] = (response) =>
  response.data?.action_categories;

export type ActionCategoryType = "product";
export type Metric = {
  title: string;
  value: number;
  format: string;
  localeOverrides: null | Record<string, string[]>;
};

export enum ActionGroup {
  DEFAULT = "default",
  BOOST_SALES = "boost_sales",
  OPTIMIZE_PRICING = "optimise_pricing",
  REDUCE_COSTS = "reduce_costs",
  WIN_CUSTOMERS = "win_customers",
}

export type Action = {
  action_name: string;
  action_group: ActionGroup;
  key_pattern: string;
  sentence: string;
  why_trait_analysis: string;
  based_on_days: number;
};

export type ActionWithCategory = Action & {
  category: ActionCategoryType;
};

export type ActionCategory = {
  category: ActionCategoryType;
  default_action: string;
  actions: Action[];
};

export interface ActionMetadataInterface {
  action_categories: ActionCategory[];
}

export interface ExternalLinks {
  connector_type: ConnectorType;
  title: string;
  link: string;
}

export interface ActionItem {
  id: string;
  title: string;
  image: string;
  action_item: string;
  action_type: string;
  attributes: string[];
  headline_metric: Metric;
  metrics: Metric[];
  action_priority: number;
  external_links: ExternalLinks[];
}

export interface ActionDataInterface {
  action_categories: ActionItem[];
}

const fetchActionMetadata = async (organisationId: number): Promise<Response<ActionMetadataInterface>> =>
  await makeQueryRequest(`${HOST}/v1/actions/metadata?organisation_id=${organisationId}`);
const fetchActionData = async (
  organisationId: number,
  category: string,
  action: string
): Promise<Response<ActionDataInterface>> =>
  await makeQueryRequest(`${HOST}/v1/actions?organisation_id=${organisationId}&category=${category}&action=${action}`);
const fetchActionExportUrl = async (organisationId: number): Promise<Response<{ url: string }>> =>
  await makeQueryRequest(`${HOST}/v1/actions/export?organisation_id=${organisationId}`);

type HideActionResponse = Response<{
  action: {
    organisation_id: string;
    timestamp: number;
    action_id: string;
  };
}>;

const hideAction = async (organisationId: number, actionId: string): Promise<HideActionResponse> =>
  await makeQueryRequest(`${HOST}/v1/actions/hide?organisation_id=${organisationId}`, {
    method: HttpMethod.POST,
    body: { action_id: actionId },
  });

const queryKeys = {
  useActionData: (
    currentOrganisationId: number | undefined,
    category: ActionCategoryType | undefined,
    action: string
  ) => ["actionMetadata", currentOrganisationId, category, action],
};

const actions = {
  queryKeys: queryKeys,
  useActionSummary: (currentOrganisationId: number | undefined, category: string, action: string) =>
    useQuery({
      queryKey: ["actionSummary", currentOrganisationId, category, action],
      queryFn: () => fetchActionSummary(currentOrganisationId, category, action),
      select: selectActionSummary,
      enabled: typeof currentOrganisationId === "number",
    }),
  useActionMetadata: (currentOrganisationId: number | undefined) =>
    useQuery({
      queryKey: ["actionMetadata", currentOrganisationId],
      queryFn: () => fetchActionMetadata(currentOrganisationId!),
      enabled: typeof currentOrganisationId === "number",
    }),
  useActionData: (
    currentOrganisationId: number | undefined,
    category: ActionCategoryType | undefined,
    actions: string[]
  ) =>
    useQueries({
      queries: actions.map((action) => ({
        queryKey: queryKeys.useActionData(currentOrganisationId, category, action),
        queryFn: () => fetchActionData(currentOrganisationId!, category!, action!),
        enabled: typeof currentOrganisationId === "number" && !!category && !!action,
      })),
    }),
  useActionExport: (
    currentOrganisationId: number | undefined,
    onSuccess: (data: Response<{ url: string }, number, 200>) => void
  ) =>
    useQuery({
      queryKey: ["actionExport", currentOrganisationId],
      queryFn: () => fetchActionExportUrl(currentOrganisationId!),
      enabled: false,
      onSuccess: onSuccess,
    }),
  useHideAction: (
    currentOrganisationId: number | undefined,
    onSuccess: (data: HideActionResponse) => void,
    onError: () => void
  ) =>
    useMutation({
      mutationFn: (actionId: string) => hideAction(currentOrganisationId!, actionId),
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: () => {
        onError();
      },
    }),
};

export default actions;
