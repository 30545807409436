import makeRequest from "common/services/APIService/makeRequest";
import { HOST } from "common/utils/constants";

export interface RevenueBandType {
  id: number;
  minimum?: number;
  maximum?: number;
}

export type OrganisationType = {
  id: number;
  name: string;
  domain: string;
  public_id?: string;
  organisation_config: {
    currency: string;
    category?: string;
    subcategory?: string;
    revenue_band?: RevenueBandType;
  };
};

const organisations = {
  list: () => makeRequest(`${HOST}/v1/organisations`),
  create: (data: Omit<OrganisationType, "id">) =>
    makeRequest(`${HOST}/v1/organisations`, { method: "POST", body: data }),
  get: (data: Pick<OrganisationType, "id">) => makeRequest(`${HOST}/v1/organisations/${data.id}`),
  update: (data: OrganisationType) => makeRequest(`${HOST}/v1/organisations/${data.id}`, { method: "PUT", body: data }),
  leave: (data: Pick<OrganisationType, "id">) => makeRequest(`${HOST}/v1/organisations/${data.id}/leave`),
  transferOwnership: (data: { organisationId: number; data: { id: number } }) =>
    makeRequest(`${HOST}/v1/organisations/${data.organisationId}/transfer`, { method: "PUT", body: data.data }),
};

export default organisations;
