import { useQuery } from "@tanstack/react-query";
import { makeQueryRequest, Response } from "common/services/APIService";
import { HOST } from "common/utils/constants";

import { ConnectorAuthorisationResponse, FetchConnectorAuthsQueryParams } from "./types";

const fetchConnectorsAuths = async ({
  partnerId,
  organisationId,
}: FetchConnectorAuthsQueryParams): Promise<Response<ConnectorAuthorisationResponse>> => {
  return await makeQueryRequest(
    `${HOST}/v1/partner-portal/connector_authorisations?partner_id=${partnerId}&organisation_id=${organisationId}`
  );
};

const queryKeys = {
  useConnectorAuths: (organisationId: number) => ["connectorAuthorisations", organisationId],
};

const connectorAuthorisations = {
  queryKeys,
  useConnectorAuths: (queryParams: FetchConnectorAuthsQueryParams) =>
    useQuery({
      queryKey: queryKeys.useConnectorAuths(queryParams.organisationId),
      queryFn: () => fetchConnectorsAuths(queryParams),
      enabled: typeof queryParams.organisationId === "number",
    }),
};

export default connectorAuthorisations;
