import { REACT_APP_COOKIE_DOMAIN, REACT_APP_ENV } from "common/utils/constants";
import Cookies from "universal-cookie";
import { CookieSetOptions } from "universal-cookie/cjs/types";

const _cookies = new Cookies();

export const CookieNames = {
  DOMAIN: {
    name: `${REACT_APP_ENV}_domain`,
    options: { domain: REACT_APP_COOKIE_DOMAIN, path: "/" },
  },
  ORGANISATION_ID: {
    name: `${REACT_APP_ENV}_organisation_id`,
    options: { domain: REACT_APP_COOKIE_DOMAIN, path: "/" },
  },
  SIGNUP_EMAIL: {
    name: `${REACT_APP_ENV}_signUpEmail`,
    options: { domain: REACT_APP_COOKIE_DOMAIN, path: "/" },
  },
  CONNECTOR_AUTH_ID: {
    name: `${REACT_APP_ENV}_connector_auth_id`,
    options: { domain: REACT_APP_COOKIE_DOMAIN, path: "/" },
  },
  ID_TOKEN: {
    name: `${REACT_APP_ENV}_idToken`,
    options: { domain: REACT_APP_COOKIE_DOMAIN, path: "/" },
  },
  STEPS_STATE: {
    name: `${REACT_APP_ENV}_stepsState`,
    options: { domain: REACT_APP_COOKIE_DOMAIN, path: "/", maxAge: 900 }, // Expiry of 15 minutes.
  },
} as const;

export type CookieType = { name: (typeof CookieNames)[keyof typeof CookieNames]["name"]; options?: CookieSetOptions };

export default class CookieService {
  static cookies = _cookies;

  static set = (cookie: CookieType, value: string) => {
    CookieService.cookies.set(cookie.name, value, cookie.options);
  };
  static get = (cookie: CookieType) => {
    return CookieService.cookies.get(cookie.name);
  };
  static remove = (cookie: CookieType) => {
    CookieService.cookies.remove(cookie.name, cookie.options);
  };

  static getAll = () => {
    return CookieService.cookies.getAll();
  };

  static removeAll = () => {
    Object.values(CookieNames).forEach((cookie) => CookieService.remove(cookie));
  };
}
