import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AmplifyService from "app/services/AmplifyService";
import LOCALES, { Locale } from "common/i18n/locales";

// Define a type for the slice state
interface I18nState {
  locale: Locale;
}

// Define the initial state using that type
export const initialState: I18nState = {
  locale: LOCALES.ENGLISH,
};

export const updateLocale = createAsyncThunk("i18n/updateLocale", async (locale: Locale) => {
  await AmplifyService.updateLocale(locale);

  return locale;
});

export const initializeLocale = createAsyncThunk("i18n/initializeLocale", async () => {
  const { success, user } = await AmplifyService.getUser();

  // The locale field of a user is only set if the user has changed it
  return (success && user.attributes.locale) || LOCALES.ENGLISH;
});

export const localeSlice = createSlice({
  name: "i18n",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(updateLocale.fulfilled, (state, action) => {
      state.locale = action.payload;
    });
    builder.addCase(initializeLocale.fulfilled, (state, action) => {
      state.locale = action.payload;
    });
  },
  reducers: {},
});

export default localeSlice.reducer;
