export const enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
  OPTIONS = "OPTIONS",
  HEAD = "HEAD",
}

export enum HttpStatusCode {
  NOT_FOUND = 404,
  UNAUTHORIZED = 401,
}

export type APIServiceFunctionArg<APIServiceFunction extends (...args: any[]) => any> =
  Parameters<APIServiceFunction>[0];

export type SuccessResponse<Data, StatusCode = 200> = {
  data: Data;
  status_code: StatusCode;
  success: true;
  error_code: null;
  message: "success";
};

export type ErrorResponse<Data = any, StatusCode = number> = {
  data: Data;
  status_code: StatusCode;
  success: false;
  error_code: string;
  message: string;
};

export type Response<Data, ErrorCode = number, SuccessCode = 200> =
  | SuccessResponse<Data, SuccessCode>
  | ErrorResponse<Data, ErrorCode>;
