import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import APIService, { APICallState, APICallStateType } from "app/services/APIService";
import { APIServiceFunctionArg } from "common/services/APIService/types";
import { AsyncThunkReturnType } from "common/slices/types";

export const getConnectorConfigSchema = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.connectors.getConfigSchema>
>(
  "connectors/config/getConnectorConfigSchema",
  async (data) => await APIService.connectors.getConfigSchema(data).then((res) => res.json())
);

export const submitConfig = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.connectors.submitConfig>
>(
  "connectors/config/submitConfig",
  async (data) => await APIService.connectors.submitConfig(data).then((res) => res.json())
);

export const reauthenticateConnector = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.connectors.reauthenticateConnector>
>(
  "connectors/config/reauthenticateConnector",
  async (data) => await APIService.connectors.reauthenticateConnector(data).then((res) => res.json())
);

export enum HealthCheckEnum {
  PENDING = "pending",
  SUCCESS = "success",
  FAILURE = "failure",
}

export interface ConnectionStatus {
  status: string;
  message: string;
}

export interface HealthCheckData {
  type: string;
  connectionStatus: ConnectionStatus;
}

export interface HealthCheck {
  status: HealthCheckEnum;
  message: string | null;
  healthCheckData: HealthCheckData | null;
}

export interface ConnectorConfig {
  schema: any;
  ui_schema: any;
  connector_type: string;
  oauth_flow: boolean;
  airbyte_name: string;
}

export interface ConfigState {
  connectorConfig: ConnectorConfig | undefined;
  status: APICallStateType;
  submitStatus: APICallStateType;
  errorMessage: string | undefined;
  healthCheck: HealthCheck | undefined;
}

// Define the initial state using that type
export const initialState: ConfigState = {
  connectorConfig: undefined,
  status: undefined,
  submitStatus: undefined,
  errorMessage: undefined,
  healthCheck: undefined,
};

export const configSlice = createSlice({
  name: "config",
  initialState: initialState,
  reducers: {
    resetConnectorConfig() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConnectorConfigSchema.pending, (state) => {
      state.status = APICallState.LOADING;
    });

    builder.addCase(getConnectorConfigSchema.fulfilled, (state, action) => {
      if (action.payload.status_code === 200) {
        state.status = APICallState.SUCCESS;
        state.connectorConfig = action.payload.data.connector;
      } else {
        state.status = APICallState.FAILED;
        if (action.payload.status_code === 400) {
          state.errorMessage = action.payload.message;
        }
      }
    });

    builder.addCase(getConnectorConfigSchema.rejected, (state) => {
      state.status = APICallState.FAILED;
    });

    builder.addCase(submitConfig.pending, (state) => {
      state.submitStatus = APICallState.LOADING;
      state.healthCheck = { healthCheckData: null, message: null, status: HealthCheckEnum.PENDING };
    });

    builder.addCase(submitConfig.fulfilled, (state, action) => {
      if (action.payload.status_code === 201) {
        state.submitStatus = APICallState.SUCCESS;
        state.healthCheck = { healthCheckData: null, message: null, status: HealthCheckEnum.SUCCESS };
      } else {
        state.submitStatus = APICallState.FAILED;
        if (action.payload.status_code === 400) {
          if (action.payload.message) {
            state.healthCheck = {
              healthCheckData: action.payload.data,
              message: action.payload.message,
              status: HealthCheckEnum.FAILURE,
            };
          } else {
            state.healthCheck = {
              healthCheckData: action.payload.data,
              message: null,
              status: HealthCheckEnum.FAILURE,
            };
          }
        } else {
          state.healthCheck = {
            healthCheckData: action.payload.data,
            message: null,
            status: HealthCheckEnum.FAILURE,
          };
        }
      }
    });

    builder.addCase(submitConfig.rejected, (state) => {
      state.submitStatus = APICallState.FAILED;
      state.healthCheck = {
        healthCheckData: null,
        message: null,
        status: HealthCheckEnum.FAILURE,
      };
    });

    builder.addCase(reauthenticateConnector.pending, (state) => {
      state.submitStatus = APICallState.LOADING;
      state.healthCheck = { healthCheckData: null, message: null, status: HealthCheckEnum.PENDING };
    });

    builder.addCase(reauthenticateConnector.fulfilled, (state, action) => {
      if (action.payload.status_code === 200) {
        state.submitStatus = APICallState.SUCCESS;
        state.healthCheck = { healthCheckData: null, message: null, status: HealthCheckEnum.SUCCESS };
      } else {
        state.submitStatus = APICallState.FAILED;
        if (action.payload.status_code === 400) {
          if (action.payload.message) {
            state.healthCheck = {
              healthCheckData: action.payload.data,
              message: action.payload.message,
              status: HealthCheckEnum.FAILURE,
            };
          } else {
            state.healthCheck = {
              healthCheckData: action.payload.data,
              message: null,
              status: HealthCheckEnum.FAILURE,
            };
          }
        } else {
          state.healthCheck = {
            healthCheckData: action.payload.data,
            message: null,
            status: HealthCheckEnum.FAILURE,
          };
        }
      }
    });

    builder.addCase(reauthenticateConnector.rejected, (state) => {
      state.submitStatus = APICallState.FAILED;
      state.healthCheck = {
        healthCheckData: null,
        message: null,
        status: HealthCheckEnum.FAILURE,
      };
    });
  },
});

export const { resetConnectorConfig } = configSlice.actions;
export default configSlice.reducer;
