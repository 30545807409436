// Important Note: These Migrations are only for keys whitelisted under redux persist config.
import v3 from "./v3";
import v4 from "./v4";
import v5 from "./v5";
import v6 from "./v6";

export default {
  3: v3,
  4: v4,
  5: v5,
  6: v6,
};
