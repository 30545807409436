import authReducer from "common/slices/connectors/auth";
import configReducer from "common/slices/connectors/config";
import connectorsReducer from "common/slices/connectors/connectors";
import { combineReducers } from "redux";

export default combineReducers({
  connectors: connectorsReducer,
  config: configReducer,
  connectorAuth: authReducer,
});
