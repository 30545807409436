import WebsocketHandler from "app/services/WebSocketAPIService/WebsocketHandler";

import useWebSocket from "./hooks/useWebSocket";
import { WebsocketRequest, WebsocketRequestState } from "./types";

export const WebsocketRequestStatusEnum = {
  REQUESTED: "requested",
  RECEIVED: "received",
  FAILED: "failed",
} as const;

export type WebsocketRequestStatusEnum = (typeof WebsocketRequestStatusEnum)[keyof typeof WebsocketRequestStatusEnum];

export type MetricRequestStatus = {
  metric?: string;
  status: WebsocketRequestStatusEnum;
};

type SendWebsocketMessageType = {
  uid: string;
  requests: { [key: string]: WebsocketRequestState };
  websocket: WebsocketHandler;
  payload: WebsocketRequest;
  cache?: boolean;
};

export const sendWebsocketMessage = ({
  uid,
  requests,
  websocket,
  payload,
  cache = false,
}: SendWebsocketMessageType): { [key: string]: WebsocketRequestState } => {
  if (requests[uid]) {
    if (requests[uid].status === WebsocketRequestStatusEnum.REQUESTED) {
      // Skip sending request if one with the same uid has already been requested
      return requests;
    }

    if (cache && requests[uid].status === WebsocketRequestStatusEnum.RECEIVED) {
      return requests;
    }
  }

  websocket.sendAuthenticatedMessage(payload.organisation_id, {
    uid,
    payload,
  });

  requests[uid] = {
    requestPayload: payload,
    status: WebsocketRequestStatusEnum.REQUESTED,
  };
  return requests;
};

export const receiveWebsocketMessage = ({
  uid,
  requests,
  responsePayload,
}: {
  uid: string;
  requests: { [key: string]: WebsocketRequestState };
  responsePayload: any;
}): { [key: string]: WebsocketRequestState } => {
  if (requests[uid]) {
    requests[uid] = {
      ...requests[uid],
      status: WebsocketRequestStatusEnum.RECEIVED,
      responsePayload,
    };
  }
  return requests;
};

export { useWebSocket };
