import makeRequest from "common/services/APIService/makeRequest";
import { HOST } from "common/utils/constants";
import queryString from "query-string";

const connector_authorisations = {
  list: (organisation_id: number) =>
    makeRequest(`${HOST}/v1/connector_authorisations?organisation_id=${organisation_id}`),
  get: (data: { id: number; organisation_id: number }) =>
    makeRequest(`${HOST}/v1/connector_authorisations/${data.id}?organisation_id=${data.organisation_id}`),
  getOAuthStartUrl: ({
    connector_type,
    organisation_id,
    data,
  }: {
    connector_type: string;
    organisation_id: number;
    data: Record<string, any>;
  }) =>
    makeRequest(
      `${HOST}/v1/oauth-start?${queryString.stringify({
        connector_type,
        organisation_id,
        data: JSON.stringify(data),
      })}`
    ),
  deleteAuth: ({
    connector_authorisation_id,
    organisation_id,
  }: {
    connector_authorisation_id: number;
    organisation_id: number;
  }) =>
    makeRequest(
      `${HOST}/v1/connector_authorisations/${connector_authorisation_id}?organisation_id=${organisation_id}`,
      {
        method: "DELETE",
      }
    ),
};

export default connector_authorisations;
