import * as Sentry from "@sentry/react";
import { formatLocale, formatSpecifier } from "d3";

interface D3SiPrefixMap {
  [key: string]: string;
  y: string;
  z: string;
  a: string;
  f: string;
  p: string;
  n: string;
  µ: string;
  m: string;
  "": string;
  k: string;
  M: string;
  G: string;
  T: string;
  P: string;
  E: string;
  Z: string;
  Y: string;
}

const getDefaultValueSpecifier = (specifier: string) => {
  const defaultSpecifier = formatSpecifier(specifier);
  defaultSpecifier.type = "f";
  defaultSpecifier.trim = true;
  defaultSpecifier.precision = 2;
  return defaultSpecifier.toString();
};

export const getD3Locale = (localeOverrides: any) => {
  const baseLocale = {
    decimal: ".",
    thousands: ",",
    grouping: [3],
    currency: ["$", ""],
  };

  return {
    ...formatLocale({ ...baseLocale, ...localeOverrides }),
    format: (specifier: string) => {
      try {
        // https://github.com/eaglepointpartners/conjura/issues/19177
        // This function is for overriding the default si prefixes from the d3 format
        const locale = formatLocale({ ...baseLocale, ...localeOverrides });
        const formattedSpecifier = formatSpecifier(specifier);
        const valueFormatter = locale.format(specifier);
        const defaultValueFormatter = locale.format(getDefaultValueSpecifier(specifier));

        const d3SiPrefixMap: D3SiPrefixMap = {
          y: "e-24",
          z: "e-21",
          a: "e-18",
          f: "e-15",
          p: "e-12",
          n: "e-9",
          µ: "e-6",
          m: "e-3",
          "": "",
          k: "K",
          M: "M",
          G: "B",
          T: "T",
          P: "P",
          E: "E",
          Z: "Z",
          Y: "Y",
        };
        return (value: number) => {
          if (formattedSpecifier.type === "s") {
            if (Math.abs(value) < 0.005) {
              return defaultValueFormatter(0);
            } else if (Math.abs(value) < 1000) {
              return defaultValueFormatter(value);
            } else {
              const result = valueFormatter(value);
              const lastChar = result[result.length - 1];
              if (Object.keys(d3SiPrefixMap).includes(lastChar)) {
                return result.slice(0, -1) + d3SiPrefixMap[lastChar];
              }
            }
          }
          return valueFormatter(value);
        };
      } catch (e) {
        return (value: number) => {
          console.warn(e);
          Sentry.captureException(e);
          return value.toString();
        };
      }
    },
    formatPrecision: (specifier: string) => {
      // This returns a function which can get a value rounded to the precision value of a format specifier.
      const { precision, type } = formatSpecifier(specifier);
      const allowedTypes = ["%", "f"];
      const getValue = (value: number, type: string) => {
        if (type === "%") {
          return value * 100;
        }
        return value;
      };

      // Example if specifier = ".1f" and value = 1.23456789, then the value returned is 1.2
      return (value: number) => {
        if (precision && allowedTypes.includes(type)) {
          const precisionValue = Math.pow(10, precision);
          return Math.round((getValue(value, type) + Number.EPSILON) * precisionValue) / precisionValue;
        }
        return value;
      };
    },
  };
};

export const getReadableTime = (stringDateTime: string) => {
  const readableDateTime = new Date(stringDateTime);
  return `${readableDateTime.toLocaleDateString("en-GB")}, ${readableDateTime.toLocaleTimeString("en-GB")}`;
};

export const errorMessageParser = (message: string) => {
  const parsedMessage = message.replace("BadRequestError: ", "");
  try {
    return JSON.parse(parsedMessage);
  } catch (e) {
    return parsedMessage;
  }
};

export const getPercentageChange = (oldNumber: number, newNumber: number, round = 0, abs = false) => {
  const change = ((newNumber - oldNumber) / oldNumber) * 100;
  return abs ? Math.abs(change).toFixed(round) : change.toFixed(round);
};

export const getRgbColorStringFromHexCode = (hex?: string): string => {
  if (!hex) return "";
  const hexCode = hex.startsWith("#") ? hex.substring(1) : hex;

  // Convert hex to decimal
  const r = parseInt(hexCode.slice(0, 2), 16);
  const g = parseInt(hexCode.slice(2, 4), 16);
  const b = parseInt(hexCode.slice(4, 6), 16);

  return `${r},${g},${b}`;
};

export const downloadFile = (url: string) => {
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  const fileName = url.split("/").pop() as string;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};

export const search = (searchKey: string, keywords: string[]) => {
  for (const keyword of keywords) {
    if (keyword.toLowerCase().includes(searchKey.toLowerCase())) return true;
  }
  return false;
};

export const sha256 = async (str: string) => {
  return await crypto.subtle.digest("SHA-256", new TextEncoder().encode(str));
};

export const generateNonce = async () => {
  const hash = await sha256(crypto.getRandomValues(new Uint32Array(4)).toString());
  const hashArray = Array.from(new Uint8Array(hash));
  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
};

export const base64URLEncode = (str: ArrayBuffer) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
};

export const generatePairForPKCE = async () => {
  const codeVerifier = await generateNonce();
  const codeChallenge = base64URLEncode(await sha256(codeVerifier));
  return {
    codeVerifier,
    codeChallenge,
  };
};

export const isMarkdown = (data: string) => {
  // Check for common Markdown syntax patterns
  const markdownRegex = /[#*`\-[\]()>!+_|]/;

  // Return true if any Markdown syntax is found in the data
  return markdownRegex.test(data);
};
