import { useMutation } from "@tanstack/react-query";
import { makeQueryRequest, HttpMethod, Response } from "common/services/APIService";
import { HOST } from "common/utils/constants";

type CreateBillingPortalRequestPayload = { organisation_id?: number; public_id?: string };
type CreateCheckoutRequestPayload = {
  organisation_id: number;
  product_line: string;
  success_url: string;
  cancel_url: string;
};

type CreateBillingPortalResponse = { url: string };
type CreateCheckoutResponse = { url: string };

const createBillingPortal = async (
  requestPayload: CreateBillingPortalRequestPayload
): Promise<Response<CreateBillingPortalResponse>> =>
  await makeQueryRequest(`${HOST}/v1/create-customer-portal-session`, {
    method: HttpMethod.POST,
    body: requestPayload,
  });

const createCheckout = async (
  requestPayload: CreateCheckoutRequestPayload
): Promise<Response<CreateCheckoutResponse>> =>
  await makeQueryRequest(`${HOST}/v1/create-customer-checkout-session`, {
    method: HttpMethod.POST,
    body: requestPayload,
  });

const stripe = {
  useCreateBillingPortal: ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data: Response<CreateBillingPortalResponse>) => void;
    onError: () => void;
  }) =>
    useMutation({
      mutationFn: (requestPayload: CreateBillingPortalRequestPayload) => createBillingPortal(requestPayload),
      onSuccess: (data) => onSuccess(data),
      onError: () => onError(),
    }),
  useCreateCheckout: ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data: Response<CreateCheckoutResponse>) => void;
    onError: () => void;
  }) =>
    useMutation({
      mutationFn: (requestPayload: CreateCheckoutRequestPayload) => createCheckout(requestPayload),
      onSuccess: (data) => onSuccess(data),
      onError: () => onError(),
    }),
};

export default stripe;
