import { useQuery } from "@tanstack/react-query";
import { makeQueryRequest, Response } from "common/services/APIService";
import { HOST } from "common/utils/constants";

export interface ConnectorAuthorisation {
  backfill_finished: boolean;
  connector_category: string;
  connector_type: string;
  connector_type_name: string;
  created_at: string;
  created_by: string;
  destinations_synced: string[];
  id: number;
  organisation_id: number;
  remote_identifier?: string;
  status: string;
  updated_at: string;
}

const fetchConnectorsAuths = async (organisation_id: number | undefined): Promise<Response<any>> => {
  return await makeQueryRequest(`${HOST}/v1/connector_authorisations?organisation_id=${organisation_id}`);
};

const selectConnectorAuths: (response: {
  data: { connector_authorisations: ConnectorAuthorisation[] };
}) => ConnectorAuthorisation[] = (response) => response.data?.connector_authorisations;

const connectorAuthorisationsV2 = {
  useConnectorAuths: (currentOrganisationId: number | undefined) =>
    useQuery({
      queryKey: ["connectorAuthorisations", currentOrganisationId],
      queryFn: () => fetchConnectorsAuths(currentOrganisationId),
      select: selectConnectorAuths,
      enabled: typeof currentOrganisationId === "number",
    }),
};

export default connectorAuthorisationsV2;
