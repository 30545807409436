import { gaEventTracker } from "common/services/GAService";
import { Middleware, MiddlewareAPI, Dispatch, AnyAction } from "redux";

const mapActionGAEvent: Record<string, (state: any, action: AnyAction) => void> = {
  "auth/logoutUser/fulfilled": () => {
    gaEventTracker.trackSignOut();
  },
  "members/inviteMembers/fulfilled": () => {
    gaEventTracker.trackInviteMembers();
  },
  "organisations/createOrganisationAndSetCookies/fulfilled": () => {
    gaEventTracker.trackOrganisationSetup();
  },
};

const GAMiddleware: Middleware = (api: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
  const result = next(action);

  const gaEventTracker = mapActionGAEvent[action.type];

  if (gaEventTracker) gaEventTracker(api.getState(), action);

  return result;
};

export default GAMiddleware;
