import AmplifyService from "app/services/AmplifyService";

type RequestParams = Omit<RequestInit, "body"> & {
  body?: Record<string, any>;
};

const makeRequest = async (path: string, params: RequestParams = {}, auth = true) => {
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  if (auth) {
    headers["Authorization"] = await AmplifyService.getToken();
  }

  const defaultRequestParams: RequestInit = {
    method: "GET",
    headers,
  };

  const body = params.body ? JSON.stringify(params.body) : undefined;
  return fetch(path, { ...defaultRequestParams, ...params, body });
};

export default makeRequest;
