import { useMutation } from "@tanstack/react-query";
import { makeQueryRequest, HttpMethod, Response } from "common/services/APIService";
import { HOST } from "common/utils/constants";

const requestPartnerAccessToOrgs = async (organisationId: number, partnerId: number): Promise<Response<null>> => {
  return await makeQueryRequest(
    `${HOST}/v1/partner-portal/organisations/grant-partner-org-access?partner_id=${partnerId}&organisation_id=${organisationId}`,
    {
      method: HttpMethod.POST,
    }
  );
};

const revokePartnerAccessToOrgs = async (organisationId: number, partnerId: number): Promise<Response<null>> => {
  return await makeQueryRequest(
    `${HOST}/v1/partner-portal/organisations/revoke-partner-org-access?partner_id=${partnerId}&organisation_id=${organisationId}`,
    {
      method: HttpMethod.POST,
    }
  );
};

const partnerAccessToOrgs = {
  useRequestPartnerAccessToOrgs: ({
    organisationId,
    onSuccess,
    onError,
  }: {
    organisationId: number;
    onSuccess: (data: Response<null>) => void;
    onError: () => void;
  }) =>
    useMutation({
      mutationFn: (partnerId: number) => requestPartnerAccessToOrgs(organisationId, partnerId),
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError,
    }),

  useRevokePartnerAccessToOrgs: ({
    organisationId,
    onSuccess,
    onError,
  }: {
    organisationId: number;
    onSuccess: (data: Response<null>) => void;
    onError: () => void;
  }) =>
    useMutation({
      mutationFn: (partnerId: number) => revokePartnerAccessToOrgs(organisationId, partnerId),
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (data: any) => {
        if (data.status_code === 409) {
          return;
        }
        onError();
      },
    }),
};

export default partnerAccessToOrgs;
