import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum SidebarStateEnum {
  expanded = "expanded",
  collapsed = "collapsed",
}

export interface UserPreferences {
  sidebarState: SidebarStateEnum;
  sidebarSubMenuState: Record<string, SidebarStateEnum>;
}

// Define the initial state using that type
export const initialState: UserPreferences = {
  sidebarState: SidebarStateEnum.expanded,
  sidebarSubMenuState: {},
};

export const userPreferenceSlice = createSlice({
  name: "userPreferences",
  initialState: initialState,
  reducers: {
    setSideBarToggle(state, action: PayloadAction<SidebarStateEnum>) {
      state.sidebarState = action.payload;
    },
    setSideBarSubMenuToggle(state, action: PayloadAction<Record<string, SidebarStateEnum>>) {
      state.sidebarSubMenuState = { ...(state.sidebarSubMenuState || {}), ...action.payload };
    },
  },
});

export const { setSideBarToggle, setSideBarSubMenuToggle } = userPreferenceSlice.actions;
export default userPreferenceSlice.reducer;
