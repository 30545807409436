import AmplifyService from "app/services/AmplifyService";
import httpMessageHandler from "app/services/APIService/httpMessageHandler";
import { getRequestUid, isStringLargerThanBytes } from "app/services/WebSocketAPIService/utils";
import { REACT_APP_WEBSOCKET_HOST } from "common/utils/constants";

const getWebsocketHost = (currentOrganisationId: number, accessToken: string) => {
  return `${REACT_APP_WEBSOCKET_HOST}?organisation_id=${currentOrganisationId}&access_token=${accessToken}`;
};

export const getTokenRequestUid = (currentOrganisationId: number) => {
  return getRequestUid({ organisation_id: currentOrganisationId, type: "token" });
};

export type JsonMessageListener = (jsonMessage: Record<string, any>) => void;

export const readyState = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};

enum ChunkTypes {
  CHUNK = ":",
  END_CHUNK = ";",
}

export const defaultMessageSizeInBytes = 129024;

class WebsocketHandler {
  ws: WebSocket | null = null;
  token: string | null = null;
  messageListeners: JsonMessageListener[] = [];
  currentAttempt = 0;
  reconnectAttempts = 20;
  reconnectInterval = 3000;
  chunks: Record<string, (string | null)[]> = {};
  pendingMessages: Array<Record<string, unknown>> = [];

  shouldReconnect = (message: CloseEvent) => {
    /*
    This will check if the websocket should be reconnected or not. Total number of reconnect attempts is set to 20.

    Connection could be closed due to various reasons & will need reconnection attempt. Few of them are:
    1. Network issue on the client side.
    2. 10 minutes timeout by AWS APIGateway Websocket limits: (close event code in this case is 1001.)
    https://docs.aws.amazon.com/apigateway/latest/developerguide/limits.html
    3. A new deployment which forces a close event from server.
     */
    if (this.currentAttempt > this.reconnectAttempts) {
      return false;
    }
    this.currentAttempt += 1;
    console.log({ code: message.code });
    switch (message.code) {
      case 1000:
        return false;
      case 1001: // When AWS APIGateway closes connection after 10 minutes of inactivity.
        return true;
      default:
        return true;
    }
  };

  close = (code: number, reason?: string) => {
    if (this.ws) {
      this.ws.close(code, reason);
      this.token = null;
    }
  };

  isChunk = (message: string) => {
    return !message.startsWith("{");
  };

  collectChunk = (message: string) => {
    /*
    Returns message if all chunks are collected, else null;
     */
    const [chunkType, chunkId] = message.split("|");
    const chunkMessageStartAt = `${chunkType}|${chunkId}|`.length;

    const chunkMessage = message.slice(chunkMessageStartAt, message.length);

    if (this.chunks[chunkId]) {
      this.chunks[chunkId].push(chunkMessage);
    } else {
      this.chunks[chunkId] = [chunkMessage];
    }

    if (chunkType === ChunkTypes.END_CHUNK) {
      const message = this.chunks[chunkId].join("");
      delete this.chunks[chunkId];
      return message;
    }
    return null;
  };

  reconnect = (currentOrganisationId: number) => {
    console.log(`Reconnecting in ${this.reconnectInterval} ms...`);
    setTimeout(() => {
      console.log(`Attempting to reconnect now...`);
      this.open(currentOrganisationId);
    }, this.reconnectInterval);
  };

  open = async (currentOrganisationId: number) => {
    this.close(1000); // Closes existing connections, if any.
    const accessToken = await AmplifyService.getToken();
    this.ws = new WebSocket(getWebsocketHost(currentOrganisationId, accessToken));

    this.ws.addEventListener("open", () => {
      console.log("Connected");
      // Authenticate after connection is established
      if (this.ws?.readyState === readyState.OPEN) {
        this.currentAttempt = 0;
        this.authenticate(currentOrganisationId);
      }
    });

    this.ws.addEventListener("close", (event) => {
      console.log("Disconnected");

      this.token = null;
      if (this.shouldReconnect(event)) {
        this.reconnect(currentOrganisationId);
      }
    });

    this.ws.addEventListener("message", (event) => {
      let data = event.data;

      if (this.isChunk(data)) {
        data = this.collectChunk(data);
        if (!data) {
          // Skip listener execution if the chunks are not fully received.
          return;
        }
      }

      const message = JSON.parse(data);

      if (message.uid === getTokenRequestUid(currentOrganisationId)) {
        this.setToken(currentOrganisationId, message.payload.token);
      } else {
        this.messageListeners.forEach((messageListener) => {
          messageListener(message);
        });
      }
    });
  };

  addMessageListener = (listener: JsonMessageListener) => {
    this.messageListeners.push(listener);
  };

  removeMessageListener = (listener: JsonMessageListener) => {
    const index = this.messageListeners.indexOf(listener);
    this.messageListeners.splice(index, 1);
  };

  sendAuthenticatedMessage = (organisationId: string | number, message: Record<string, unknown>) => {
    if (!this.token) {
      console.log("Token not available. Adding message to pending list.");

      this.pendingMessages.push(message);

      return;
    }

    const messageJSON = JSON.stringify({
      ...message,
      token: this.token,
    });

    if (isStringLargerThanBytes(messageJSON, defaultMessageSizeInBytes)) {
      this.sendHttpMessage(organisationId, message);
    } else {
      this.sendWsMessage(messageJSON);
    }
  };

  sendHttpMessage = async (organisationId: string | number, message: any) => {
    try {
      await httpMessageHandler.sendHttpMessage(organisationId, { ...message, token: this.token });
    } catch (error) {
      console.log(error);
    }
  };

  sendWsMessage = (message: string) => {
    try {
      this.ws?.send(message);
    } catch (error) {
      console.log(error); // catch error
    }
  };

  authenticate = (currentOrganisationId: number) => {
    const tokenRequestUid = getTokenRequestUid(currentOrganisationId);
    const message = JSON.stringify({
      uid: tokenRequestUid,
      payload: { organisation_id: currentOrganisationId.toString(), type: "token", data: {} },
    });
    this.sendWsMessage(message);
  };

  setToken = (organisationId: string | number, token: string) => {
    this.token = token;

    // Send pending messages after token is received.
    if (this.pendingMessages.length > 0) {
      console.log("Sending pending messages...");

      this.pendingMessages.forEach((message) => this.sendAuthenticatedMessage(organisationId, message));
      this.pendingMessages = [];
    }
  };
}

export default WebsocketHandler;
