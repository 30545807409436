import hash from "object-hash";

export const getRequestUid = (request: Record<string, any>) => {
  return hash(request);
};

export const getRequestUids = (requests: Record<string, any>[]) => {
  return requests.map((request) => getRequestUid(request));
};

export const isStringLargerThanBytes = (s: string, maxChunkSize: number): boolean => {
  const stringSize = new Blob([s]).size;
  return stringSize > maxChunkSize;
};
