import { useQuery, useMutation } from "@tanstack/react-query";
import { makeQueryRequest, HttpMethod, Response } from "common/services/APIService";
import { HOST } from "common/utils/constants";

export type ImportType = {
  label: string;
  description: string;
  import_type: string;
};

export enum ImportStatus {
  UPLOADED = "uploaded",
  INGESTED = "ingested",
  TRANSFORMED = "transformed",
}

export type ImportedFile = {
  id: number;
  batch_id: string;
  import_name: string;
  last_imported_at: string;
  import_type: string;
  status: ImportStatus;
};

export type SpaceTheme = {
  root: {
    primaryColor: string;
    textColor: string;
    logo: string;
  };
};

const fetchImportTypes = async (organisationId?: number): Promise<Response<{ import_types: ImportType[] }>> =>
  await makeQueryRequest(`${HOST}/v1/data_import/import_types?organisation_id=${organisationId}`);

const fetchImportedFiles = async (organisationId?: number): Promise<Response<{ imported_files: ImportedFile[] }>> =>
  await makeQueryRequest(`${HOST}/v1/data_import/imported_files?organisation_id=${organisationId}`);

export const createImportFromSheet = async ({
  organisationId,
  sheetId,
}: {
  organisationId?: number;
  sheetId: string;
}): Promise<Response<unknown>> =>
  await makeQueryRequest(`${HOST}/v1/data_import/flatfile/import_from_sheet?organisation_id=${organisationId}`, {
    method: HttpMethod.POST,
    body: {
      sheet_id: sheetId,
    },
  });

const downloadImportedFile = async (
  importedFileBatchId: string,
  organisationId?: number
): Promise<Response<{ file_url: string }>> =>
  await makeQueryRequest(`${HOST}/v1/data_import/download_imported_file?organisation_id=${organisationId}`, {
    method: HttpMethod.POST,
    body: { batch_id: importedFileBatchId },
  });

const deleteImportedFiles = async (
  importedFileBatchId: string,
  organisationId?: number
): Promise<Response<{ imported_files: ImportedFile[] }>> =>
  await makeQueryRequest(`${HOST}/v1/data_import/delete_imported_file?organisation_id=${organisationId}`, {
    method: HttpMethod.DELETE,
    body: { batch_id: importedFileBatchId },
  });

export type Space = {
  id: string;
  accessToken: string;
  metadata: {
    userInfo: {
      companyId: string;
    };
  };
};

const createFlatfileSpace = async (
  importType: string,
  theme: SpaceTheme,
  organisationId?: number
): Promise<Response<Space>> =>
  await makeQueryRequest(`${HOST}/v1/data_import/flatfile_space?organisation_id=${organisationId}`, {
    method: HttpMethod.POST,
    body: {
      import_type: importType,
      theme,
    },
  });

const fetchFlatfileSpace = async (sheetId: string, organisationId?: number): Promise<Response<Space>> =>
  await makeQueryRequest(`${HOST}/v1/data_import/flatfile_space/${sheetId}?organisation_id=${organisationId}`);

const downloadTemplateFile = async (
  importType: string,
  organisationId?: number
): Promise<Response<{ file_url: string }>> =>
  await makeQueryRequest(`${HOST}/v1/data_import/download_template_file?organisation_id=${organisationId}`, {
    method: HttpMethod.POST,
    body: { import_type: importType },
  });

const queryKeys = {
  useImportTypes: (organisationId?: number) => ["importTypes", organisationId],
  useImportedFiles: (organisationId?: number) => ["importedFiles", organisationId],
};

const customDataImport = {
  queryKeys,
  useImportTypes: (organisationId?: number) =>
    useQuery({
      queryKey: queryKeys.useImportTypes(organisationId),
      queryFn: () => fetchImportTypes(organisationId),
      enabled: !!organisationId,
      select: (data) => data.data,
    }),
  useImportedFiles: (organisationId?: number) =>
    useQuery({
      queryKey: queryKeys.useImportedFiles(organisationId),
      queryFn: () => fetchImportedFiles(organisationId),
      enabled: !!organisationId,
      select: (data) => data.data,
    }),
  useDownloadImportedFile: ({
    organisationId,
    onSuccess,
  }: {
    organisationId?: number;
    onSuccess: ({ file_url }: { file_url: string }) => void;
  }) =>
    useMutation({
      mutationFn: (importedFileBatchId: string) => downloadImportedFile(importedFileBatchId, organisationId),
      onSuccess: (data) => {
        onSuccess(data.data);
      },
    }),
  useDeleteImportedFile: ({
    organisationId,
    onSuccess,
  }: {
    organisationId?: number;
    onSuccess: (data: Response<{ imported_files: ImportedFile[] }>) => void;
  }) =>
    useMutation({
      mutationFn: (importedFileBatchId: string) => deleteImportedFiles(importedFileBatchId, organisationId),
      onSuccess: (data) => {
        onSuccess(data);
      },
    }),
  useCreateFlatfileSpace: ({
    organisationId,
    onSuccess,
    onError,
  }: {
    organisationId?: number;
    onSuccess: (data: Response<Space>) => void;
    onError: (data: any) => void;
  }) =>
    useMutation({
      mutationFn: (data: { importType: string; theme: SpaceTheme }) =>
        createFlatfileSpace(data.importType, data.theme, organisationId),
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (data) => {
        onError(data);
      },
      retry: 3,
      retryDelay: 1000,
    }),
  useFetchFlatfileSpace: ({
    organisationId,
    onSuccess,
    onError,
  }: {
    organisationId?: number;
    onSuccess: (data: Response<Space>) => void;
    onError: (data: any) => void;
  }) =>
    useMutation({
      mutationFn: (data: { sheetId: string }) => fetchFlatfileSpace(data.sheetId, organisationId),
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (data) => {
        onError(data);
      },
      retry: 2,
      retryDelay: 1000,
    }),
  useDownloadTemplateFile: ({
    organisationId,
    onSuccess,
  }: {
    organisationId?: number;
    onSuccess: (data: Response<{ file_url: string }>) => void;
  }) =>
    useMutation({
      mutationFn: (importType: string) => downloadTemplateFile(importType, organisationId),
      onSuccess: (data) => {
        onSuccess(data);
      },
    }),
};

export default customDataImport;
