import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AmplifyService from "app/services/AmplifyService";
import APIServiceApp, { APICallState } from "app/services/APIService";
import { APIServiceFunctionArg } from "common/services/APIService/types";
import CookieService from "common/services/CookieService";
import { AsyncThunkReturnType } from "common/slices/types";
import APIServicePartnerPortal from "partner-portal/services/APIService";
import { unmountComponentAtNode } from "react-dom";

export const logoutUser = createAsyncThunk("auth/logoutUser", async (_, thunkAPI) => {
  await AmplifyService.signOutUser();
  CookieService.removeAll();
  const domNode = document.getElementById("root");
  if (domNode) unmountComponentAtNode(domNode);
  thunkAPI.dispatch({ type: "RESET" });
  window.location.href = "/";
  console.log("logged out");
});

// We receive { dispatch , getState} in the second argument of payloadCreator.
export const getUser = createAsyncThunk<AsyncThunkReturnType, APIServiceFunctionArg<typeof APIServiceApp.users.me>>(
  "auth/getUser",
  async () => await APIServiceApp.users.me().then((res) => res.json())
);

export const getPartnerPortalUser = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIServicePartnerPortal.users.me>
>("auth/getPartnerPortalUser", async () => await APIServicePartnerPortal.users.me().then((res) => res.json()));

export const getSubscriptions = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIServiceApp.subscriptions.list>
>("auth/getSubscriptions", async (data) => await APIServiceApp.subscriptions.list(data).then((res) => res.json()));

export enum ProvisionEnum {
  PAID_BENCHMARKS = "PAID_BENCHMARKS",
  FREE_BENCHMARKS = "FREE_BENCHMARKS",
  PAID_DASHBOARDS = "PAID_DASHBOARDS",
  FREE_DASHBOARDS = "FREE_DASHBOARDS",
}

export enum SubscriptionStatus {
  ACTIVE = "active",
  PAST_DUE = "past_due",
  FREE_TRIAL = "free_trial",
}

export interface PriceMetadata {
  provisions: ProvisionEnum;
}

export interface Price {
  id: string;
  metadata: PriceMetadata;
}

export interface SubscriptionItem {
  subscription: string;
  price: Price;
}

export interface SubscriptionItems {
  data: SubscriptionItem[];
}

export interface Subscription {
  status: SubscriptionStatus;
  start_date: string;
  cancel_at_period_end: boolean;
  current_period_end: string;
  current_period_start: string;
  customer: string;
  id: string;
  items: SubscriptionItems;
}

// Define a type for the slice state
export interface AuthState {
  user: any;
  authorization: {
    roles: string[];
    permissions: string[];
    subscriptions?: Subscription[];
  };
  status: string | undefined;
  message: string;
  signedIn: boolean;
}

// Define the initial state using that type
export const initialState: AuthState = {
  user: undefined,
  authorization: {
    roles: [],
    permissions: [],
  },
  status: undefined,
  message: "",
  signedIn: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state) => {
      state.status = APICallState.LOADING;
      state.message = "";
      state.user = undefined;
      state.authorization = {
        roles: [],
        permissions: [],
      };
    });

    builder.addCase(getUser.fulfilled, (state, action) => {
      if (action.payload.status_code === 200) {
        state.user = action.payload.data.user;
        state.status = APICallState.SUCCESS;
        state.message = "";
        state.signedIn = true;
      } else {
        state.status = APICallState.FAILED;
        state.message = action.payload?.data?.message || "";
        state.signedIn = false;
      }
    });

    builder.addCase(getUser.rejected, (state, action) => {
      state.status = APICallState.FAILED;
      state.message = action.error?.message || "";
      state.signedIn = false;
    });

    builder.addCase(getPartnerPortalUser.pending, (state) => {
      state.status = APICallState.LOADING;
      state.message = "";
      state.user = undefined;
      state.authorization = {
        roles: [],
        permissions: [],
      };
    });

    builder.addCase(getPartnerPortalUser.fulfilled, (state, action) => {
      if (action.payload.status_code === 200) {
        state.user = action.payload.data.user;
        state.status = APICallState.SUCCESS;
        state.message = "";
        state.signedIn = true;
      } else {
        state.status = APICallState.FAILED;
        state.message = action.payload?.data?.message || "";
        state.signedIn = false;
      }
    });

    builder.addCase(getPartnerPortalUser.rejected, (state, action) => {
      state.status = APICallState.FAILED;
      state.message = action.error?.message || "";
      state.signedIn = false;
    });

    builder.addCase(getSubscriptions.pending, (state) => {
      state.authorization.subscriptions = [];
    });
    builder.addCase(getSubscriptions.fulfilled, (state, action) => {
      if (action.payload.status_code === 200) {
        state.authorization.subscriptions = action.payload.data.subscriptions;
      } else {
        state.authorization.subscriptions = [];
      }
    });
    builder.addCase(getSubscriptions.rejected, (state) => {
      state.authorization.subscriptions = [];
    });
  },
});

export default authSlice.reducer;
