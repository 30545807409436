import { REACT_APP_GA_TAG_ID, REACT_APP_BUILD_TARGET, ReactAppBuildTargetTypes } from "common/utils/constants";

import utils, { isInternalUser } from "./utils";

declare const gtag: (...args: (string | Record<string, unknown>)[]) => void;

const injectGAScript = () => {
  const scriptGA = document.createElement("script");
  scriptGA.src = `https://www.googletagmanager.com/gtag/js?id=${REACT_APP_GA_TAG_ID}`;
  scriptGA.async = true;
  document.head.appendChild(scriptGA);
  console.warn("GA script injected");
  console.warn(isInternalUser());
};

const injectGA = () => {
  const scriptGACode = document.createElement("script");
  scriptGACode.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${REACT_APP_GA_TAG_ID}', {"send_page_view": false});
      `;
  document.head.appendChild(scriptGACode);
};

const setGAConfig = (config?: Record<string, any>) => {
  if (!REACT_APP_GA_TAG_ID || typeof gtag === "undefined") return;

  const commonConfig: Record<string, any> = {
    send_page_view: false,
    ...(isInternalUser() && { user_properties: { internal_user: true } }),
  };
  gtag("config", REACT_APP_GA_TAG_ID, { ...commonConfig, ...config });
};

const configure = () => {
  if (!REACT_APP_GA_TAG_ID) return;
  injectGAScript();
  injectGA();
  setGAConfig();
};

const configureUser = (userId: string) => {
  if (!REACT_APP_GA_TAG_ID || typeof gtag === "undefined") return;

  const userType = REACT_APP_BUILD_TARGET === ReactAppBuildTargetTypes.APP ? "org" : "partner";
  setGAConfig({ user_id: userId, user_type: userType });
};

const GAService = {
  configure,
  configureUser,
  utils,
};

export { default as gaEventTracker } from "./events";
export { GAProductSectionEnum } from "./types";
export type { ProductSectionType } from "./types";

export default GAService;
