import { ThunkDispatch, configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import GAMiddleware from "common/middleware/GAMiddleware";
import createRootReducer from "common/slices/reducers";
import { REACT_APP_ENV, REACT_APP_SENTRY_DSN } from "common/utils/constants";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import localforage from "localforage";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AnyAction, applyMiddleware, CombinedState, compose, StoreEnhancer, Store } from "redux";
import { persistStore, persistReducer, createMigrate } from "redux-persist";
import thunkMiddleware from "redux-thunk";

import migrations from "./migrations";

export const history = createBrowserHistory();

Sentry.init({
  environment: REACT_APP_ENV,
  dsn: REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserProfilingIntegration(),
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  normalizeDepth: 10,
  tracesSampleRate: 1.0,
  profilesSampleRate: 1.0,
});

const persistConfig = {
  key: "root",
  storage: localforage,
  version: 7, // the state version as an integer (defaults to -1), change it whenever you change the whitelisted reducer schema
  whitelist: ["i18n", "auth"], // only persist these reducers/keys
  // uses IndexDB by default, and localstorage for browsers that doesn't support IndexDB. Supported Browsers Platforms: https://github.com/localForage/localForage/wiki/Supported-Browsers-Platforms
  migrate: createMigrate(migrations),
};

export const rootReducers = (state: CombinedState<any> | undefined, action: AnyAction) => {
  if (action.type === "RESET") {
    const newState: CombinedState<any> = { router: state.router };
    return createRootReducer(history)(newState, action);
  }
  return createRootReducer(history)(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducers); // persisted root reducer with router state
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const middleware = applyMiddleware(
  routerMiddleware(history), // for dispatching history actions
  thunkMiddleware,
  GAMiddleware
);

export const enhancers: StoreEnhancer[] = [compose(sentryReduxEnhancer, middleware)];

/* provide initial state if any, we can also use redux-persist to persist data in the browser's localstorage */

export type RootState = ReturnType<typeof persistedReducer>;
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

export type AppStore = Omit<Store<RootState, AnyAction>, "dispatch"> & {
  dispatch: AppThunkDispatch;
};

const enableReduxDevTools = REACT_APP_ENV === "development" || REACT_APP_ENV === "staging";
const store: AppStore = configureStore({
  reducer: persistedReducer,
  preloadedState: {},
  devTools: enableReduxDevTools,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  enhancers,
});
export const persistor = persistStore(store);

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppThunkDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
