import { useMutation } from "@tanstack/react-query";
import { makeQueryRequest, HttpMethod } from "common/services/APIService";
import {
  REACT_APP_USER_POOL_DOMAIN,
  REACT_APP_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_COOKIE_DOMAIN,
  REACT_APP_ENV,
  type EnvType,
} from "common/utils/constants";

export interface OidcTokenResponse {
  id_token: string;
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
}

const fetchAccessToken = async (code: string, domain: string, codeVerifier: string): Promise<OidcTokenResponse> => {
  const cognitoTokenEndpointMap: Record<EnvType, string> = {
    development: `https://${REACT_APP_USER_POOL_DOMAIN}.auth.eu-west-1.amazoncognito.com/oauth2/token`,
    staging: "https://login.staging.conjura.com/oauth2/token",
    production: "https://login.conjura.com/oauth2/token",
  };

  const cognitoTokenEndpoint = cognitoTokenEndpointMap[REACT_APP_ENV];

  const redirectUriMap: Record<EnvType, string> = {
    development:
      REACT_APP_COOKIE_DOMAIN === "localhost"
        ? "http://localhost:3000/federated-login"
        : `https://${domain}.${REACT_APP_COOKIE_DOMAIN}/federated-login`,
    staging: `https://${domain}.${REACT_APP_COOKIE_DOMAIN}/federated-login`,
    production: `https://${domain}.${REACT_APP_COOKIE_DOMAIN}/federated-login`,
  };

  const redirectUri = redirectUriMap[REACT_APP_ENV];

  return await makeQueryRequest(
    cognitoTokenEndpoint,
    {
      method: HttpMethod.POST,
      body: {
        client_id: REACT_APP_USER_POOL_WEB_CLIENT_ID,
        redirect_uri: redirectUri,
        code: code,
        code_verifier: codeVerifier,
        grant_type: "authorization_code",
      },
    },
    { auth: false },
    { "Content-Type": "application/x-www-form-urlencoded" }
  );
};

const oidc = {
  useFetchAccessToken: ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data: OidcTokenResponse) => void;
    onError: () => void;
  }) =>
    useMutation({
      mutationFn: (data: { code: string; domain: string; codeVerifier: string }) =>
        fetchAccessToken(data.code, data.domain, data.codeVerifier),
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError,
    }),
};

export default oidc;
