import { useQuery } from "@tanstack/react-query";
import { DashboardAvailability, DashboardMetadataInterface } from "app/screens/Dashboard/interfaces/dashboard";
import { MetricMetadataRequest, ServerMetricMetadataResponseType } from "app/screens/Home/interfaces/requests";
import { makeQueryRequest, Response, HttpMethod } from "common/services/APIService";
import makeRequest from "common/services/APIService/makeRequest";
import { DashboardListItem } from "common/slices/metrics";
import { HOST } from "common/utils/constants";

interface DashboardsListResponse {
  dashboards: DashboardListItem[];
}

const fetchDashboardsList = async (organisationId: number): Promise<Response<DashboardsListResponse>> => {
  return await makeQueryRequest(`${HOST}/v1/dashboards?organisation_id=${organisationId}`);
};

const fetchDashboardMetaData = async (
  dashboardId: string,
  organisationId: number
): Promise<Response<DashboardMetadataInterface>> => {
  return await makeQueryRequest(`${HOST}/v1/dashboard/${dashboardId}/metadata?organisation_id=${organisationId}`);
};

const fetchDashboardAvailability = async (organisationId: number): Promise<Response<DashboardAvailability> | null> => {
  const response = await makeQueryRequest(`${HOST}/v1/dashboards/availability?organisation_id=${organisationId}`);
  if (!!response?.data?.data && !!response?.data?.connected_sources) return response;
  return null;
};

const fetchMetricMetaData = async (
  organisationId: string,
  metricMetadataRequest: MetricMetadataRequest
): Promise<Response<any>> => {
  return await makeQueryRequest(`${HOST}/v1/metrics/metadata?organisation_id=${organisationId}`, {
    method: HttpMethod.POST,
    body: metricMetadataRequest,
  });
};

const selectMetricMetadata: (response: {
  data: { metric_metadata: ServerMetricMetadataResponseType[] };
}) => ServerMetricMetadataResponseType[] = (response) => response.data?.metric_metadata;

const dashboards = {
  list: (organisation_id: number) => makeRequest(`${HOST}/v1/dashboards?organisation_id=${organisation_id}`), // TODO: Update with react-query
  useDashboardsList: (organisationId: number, enabled = true) =>
    useQuery({
      queryKey: ["dashboardsList", organisationId],
      queryFn: () => fetchDashboardsList(organisationId),
      enabled,
    }),
  useDashboardMetaData: (dashboardId: string, organisationId: number, enabled = true) =>
    useQuery({
      queryKey: ["dashboardMetaData", dashboardId, organisationId],
      queryFn: () => fetchDashboardMetaData(dashboardId, organisationId),
      enabled,
    }),
  useDashboardAvailability: (organisationId: number, enabled = true) =>
    useQuery({
      queryKey: ["dashboardAvailability", organisationId],
      queryFn: () => fetchDashboardAvailability(organisationId),
      enabled,
    }),
  useMetricMetadata: (organisationId: string, metricMetadataRequest: MetricMetadataRequest) =>
    useQuery({
      queryKey: ["metricMetadata", organisationId, metricMetadataRequest],
      queryFn: () => fetchMetricMetaData(organisationId, metricMetadataRequest),
      select: selectMetricMetadata,
    }),
};

export default dashboards;
