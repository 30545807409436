import { useMutation, useQuery } from "@tanstack/react-query";
import { DashboardInterface } from "app/screens/Dashboard/interfaces/dashboard";
import { DashboardViewCreate, DashboardViewInput } from "app/services/APIService/dashboardViews/types";
import { makeQueryRequest, Response, HttpMethod } from "common/services/APIService";
import { HOST } from "common/utils/constants";

const fetchDashboardViews = async (organisationId: number, dashboardId: string): Promise<Response<any>> =>
  await makeQueryRequest(`${HOST}/v1/dashboard_views?organisation_id=${organisationId}&dashboard_id=${dashboardId}`);

const deleteDashboardView = async (organisationId: number, dashboardViewId: string): Promise<Response<any>> =>
  await makeQueryRequest(`${HOST}/v1/dashboard_views/${dashboardViewId}?organisation_id=${organisationId}`, {
    method: HttpMethod.DELETE,
  });

const createDashboardView = async (organisationId: number, data: DashboardViewCreate) => {
  await makeQueryRequest(`${HOST}/v1/dashboard_views?organisation_id=${organisationId}`, {
    method: HttpMethod.POST,
    body: data,
  });
};

export const selectDashboardViews = (response: {
  data?: { dashboard_views?: DashboardViewInput[] };
}): DashboardViewInput[] => {
  return response.data?.dashboard_views ? response.data.dashboard_views : [];
};

const queryKeys = {
  useDashboardViews: (currentOrganisationId: number, dashboardId: string) => [
    "fetchDashboardViews",
    currentOrganisationId,
    dashboardId,
  ],
};

const dashboardViews = {
  queryKeys,
  useDashboardViews: ({
    currentOrganisationId,
    dashboardMetadata,
  }: {
    currentOrganisationId: number | undefined;
    dashboardMetadata: DashboardInterface;
  }) =>
    useQuery({
      queryKey: queryKeys.useDashboardViews(currentOrganisationId!, dashboardMetadata.id),
      queryFn: () => fetchDashboardViews(currentOrganisationId!, dashboardMetadata.id),
      select: (data) => {
        return selectDashboardViews(data);
      },
      enabled: typeof currentOrganisationId === "number",
    }),
  useDashboardViewDelete: (
    currentOrganisationId: number | undefined,
    onSuccess: () => void,
    onError: (error: any) => void
  ) =>
    useMutation({
      mutationFn: (dashboard_view_id: string) => deleteDashboardView(currentOrganisationId!, dashboard_view_id),
      onSuccess: onSuccess,
      onError: onError,
    }),
  useDashboardViewCreate: (currentOrganisationId: number, onSuccess: () => void, onError: (error: any) => void) =>
    useMutation({
      mutationFn: (data: DashboardViewCreate) => createDashboardView(currentOrganisationId, data),
      onSuccess: onSuccess,
      onError: onError,
    }),
};

export default dashboardViews;
