const LOCALES = {
  ENGLISH: "en",
  // GERMAN: "de",
} as const;

export default LOCALES;

type Keys = keyof typeof LOCALES;

export type Locale = (typeof LOCALES)[Keys];
