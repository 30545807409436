import { useQuery } from "@tanstack/react-query";
import { makeQueryRequest, Response } from "common/services/APIService";
import { HOST } from "common/utils/constants";

export enum ConnectorCategory {
  // Types based upon conjura-transform/app/var_set_bases/var_set_bases.yml

  ERP = "erp",
  ECOMMERCE = "ecommerce",
  MARKETING = "marketing",
  WEB_ANALYTICS = "web_analytics",
  EMAIL = "email",
  ECOMMERCE_SUBSCRIPTION = "ecommerce_subscription",
  MARKETPLACE = "marketplace",
}

export interface Connector {
  label: string;
  connector_type: string;
  connector_category: ConnectorCategory;
  airbyte_name: string;
  oauth_flow: boolean;
}

const fetchConnectors = async (organisation_id: number | undefined): Promise<Response<any>> => {
  return await makeQueryRequest(`${HOST}/v1/connectors?organisation_id=${organisation_id}`);
};

const selectConnectors: (response: { data: { connectors: Connector[] } }) => Connector[] = (response) =>
  response.data?.connectors;

const connectorsV2 = {
  useConnectors: (currentOrganisationId: number | undefined) =>
    useQuery({
      queryKey: ["connectors", currentOrganisationId],
      queryFn: () => fetchConnectors(currentOrganisationId),
      select: selectConnectors,
      enabled: typeof currentOrganisationId === "number",
    }),
};

export default connectorsV2;
