/**
 * * WorkFlowStateEnum and WorkflowStatusEnum are based on "customerweb-api".
 */

enum WorkflowStateEnum {
  INGESTION_RUN = "ingestion_run",
  TRANSFORMATION_RUN = "transformation_run",
}

export enum WorkflowStatusEnum {
  IN_PROGRESS = "in_progress",
  SUCCESSFUL = "successful",
  FAILED = "failed",
}

export type WorkflowStatusResponse = {
  state: WorkflowStateEnum;
  status: WorkflowStatusEnum;
  started_at: string;
  finished_at: string | null;
  last_successful_at: string | null;
};
